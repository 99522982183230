<template>
  <div id="datasource-form" class="card" style="padding:20px">
    <div class="search__actions tw-flex tw-justify-end tw-mb-2" v-if="!viewOnly">
      <h3 class="w-100"> {{ $t('New Advanced Data Source') }}</h3>
      <b-button
        id="search__clear-all"
        variant="outline-secondary"
        class="mr-1"
        @click="handleClearClick"
      >
        <span class="align-middle">{{ $t('Cancel') }}</span>
      </b-button>
      <b-button
        id="search__edit"
        :class="{ 'icon-disabled': !$can('Update', 'Data_Source') }"
        variant="primary"
        :disabled="isIncompleteInputs()"
        @click="saveButtonClicked"
      >
        <span class="align-middle">{{ $t('Save') }}</span>
      </b-button>
      <b-tooltip v-if="!canUpdate" target="search__edit" placement="left">
        {{ $t(`no-edit-${$route.params.queryType}-query-permission-text`) }}
      </b-tooltip>
    </div>
    <div>
      <div>
        <div>
          <label class="tw-flex tw-items-center tw-text-xs tw-font-medium">{{$t('Name')}}</label>
          <prozess-input
              id="name"
              v-model="resource.name"
              field="name"
              name="name"
              :disabled="viewOnly"
              :error="errorField.includes('name') ? $t('Required') : ''"
              style="padding-top: 0px"
            />
        </div>
        <!-- <div>
          <label class="tw-flex tw-items-center tw-text-xs tw-font-medium">{{$t('Key')}}</label>
          <prozess-input
              id="name"
              v-model="resource.key"
              field="name"
              name="name"
              :error="errorField.includes('key') ? $t('Required') : ''"
              style="padding-top: 0px"
            />
        </div> -->
        <div>
          <label class="tw-flex tw-items-center tw-text-xs tw-font-medium">{{$t('Query')}}</label>
          <prozess-field-wrapper :error="errorField.includes('query') ? $t('Required') : ''">
            <b-form-textarea
              :disabled="viewOnly"
              v-model="resource.query"
              :placeholder="$t('Query')"
              rows="3"
              />
          </prozess-field-wrapper>
          <p v-if="!viewOnly" style="font-size: 10px; margin-top: -18px; color:gray">{{ '\{\{company\}\}, \{\{contact\}\}' }}</p>
        </div>
      </div>
    </div>
    <div >
      <h2 class="tw-font-bold tw-text-sm">{{ $t('Filter') }}</h2>

      <label class="tw-flex tw-items-center tw-text-xs tw-font-medium">{{$t('Company')}}</label>
          <prozess-input
              id="company-filter"
              v-model="resource.companyFilterColumn"
              field="company-filter"
              name="company-filter"
              :disabled="viewOnly"
              :error="errorField.includes('company-filter') ? $t('Required') : ''"
              style="padding-top: 0px"
            />
            <p v-if="!viewOnly" style="font-size: 10px; margin-top: -18px; color:gray">{{ '\{\{this.id\}\}' }}</p>
      <br/>
       <label class="tw-flex tw-items-center tw-text-xs tw-font-medium">{{$t('Contact')}}</label>
            <prozess-input
              id="contact-filter"
              v-model="resource.contactFilterColumn"
              field="contact-filter"
              name="contact-filter"
              :disabled="viewOnly"
              :error="errorField.includes('contact-filter') ? $t('Required') : ''"
              style="padding-top: 0px"
            />
            <p v-if="!viewOnly" style="font-size: 10px; margin-top: -18px; color:gray">{{ '\{\{this.id\}\}' }}</p>
    </div>
    <br/>
    <div class="search__actions tw-flex tw-justify-end tw-mb-2">
      <b-button
        v-if="!viewOnly"
        id="search__run"
        variant="primary"
        :disabled="this.resource.query.trim() === ''"
        @click="handleRun()"
      >
        <span class="align-middle">{{ $t('Run') }}</span>
      </b-button>
      <b-button
        v-if="viewOnly"
        id="search__edit"
        :class="{ 'icon-disabled': !$can('Update', 'Data_Source') }"
        variant="primary"
        @click="$can('Update', 'Data_Source') && $emit('editMode')"
      >
        <span class="align-middle">{{ $t('Edit') }}</span>
      </b-button>
      <b-tooltip v-if="!canUpdate" target="search__edit" placement="left">
        {{ $t(`no-edit-${$route.params.queryType}-query-permission-text`) }}
      </b-tooltip>
    </div>
    <!-- START: Results column -->
    <div class="search__column column--results tw-mt-4">
      <h4 class="tw-mb-4 tw-flex tw-inline-block tw-text-body">
        {{ $t('Results') }}
      </h4>
      <div class="column__content tw-p-6 tw-rounded-lg">
        <div
          v-if="!this.runResult"
          class="tw-text-lg tw-text-center tw-text-muted tw-mt-5">
          {{ $t('No Results Loaded') }}
        </div>
        <b-table
          ref="refTable"
          v-if="this.runResult"
          :items="this.runResult.pageItems"
          :fields="fieldColumns"
          primary-key="id"
          show-empty
          :empty-text="$t('No matching records found')"
          :class="{'table-responsive' : !isShared}"
          @sort-changed="handleSortChange"
        >
          <template #table-busy>
            <div class="text-center my-2">
              <b-spinner class="align-middle" />
            </div>
          </template>

          <template #head()="{ label }">
            <span style="white-space: nowrap">{{ $t(label) }}</span>
          </template>

          <template #cell(title)="{ item }">
            <span class="text-nowrap">
              {{ item.name }}
            </span>
          </template>
        </b-table>
      </div>
    </div>
    </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex'
import { FormAction, QueryType } from '@/constants/advancedSearch'
import advancedSearchService from '@/services/advancedSearch'
import AdvancedSearchResults from '@/components/AdvancedSearch/AdvancedSearchResults.vue'
import RootEvents from '@/constants/rootEvents'
import ProzessEntitySelector from '@/components/shared/ProzessEntitySelector.vue'
import ProzessEntityFieldSelector from '@/components/shared/ProzessEntityFieldSelector.vue'
import prozessEntitySelectorMixin from '@/mixins/prozessEntitySelector'
import { dashboardService } from '@/services/dashboard'

export default {
  components: {
    AdvancedSearchResults,
    ProzessEntitySelector,
    ProzessEntityFieldSelector,
  },

  mixins: [
    /**
     * !! ATTENTION !!
     * Read the comments on top of the `@/mixins/prozessEntitySelector.js` file first before implementing this mixin.
     *
     * DO NOT DELETE THIS COMMENT.
     */
    prozessEntitySelectorMixin('advancedSearch'),
  ],
  props: {
    visible: {
      type: Boolean,
      default: false,
    },

    viewOnly: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      resource: {
        key: '',
        name: '',
        query: '',
        companyFilterColumn: null,
        contactFilterColumn: null,
      },
      errorField: [],
      runResult: null,
      FormAction,
    }
  },

  computed: {
    ...mapState({
      result: ({ advancedSearch }) => advancedSearch.result,
      id: ({ dashboard }) => dashboard.id,
    }),

    ...mapGetters({
      primaryEntityId: 'advancedSearch/primaryEntityId',
      filterOptions: 'advancedSearch/filterOptions',
      selectedTablesCount: 'advancedSearch/selectedTablesCount',
      selectedFieldsCount: 'advancedSearch/selectedFieldsCount',
      selectedFiltersCount: 'advancedSearch/selectedFiltersCount',
      filteringFields: 'advancedSearch/filteringFields',
    }),

    canUpdate() {
      const queryType = this.$route.params.queryType === QueryType.GLOBAL
        ? 'Global_Query'
        : 'Personal_Query'

      return this.$can('Update', queryType)
    },

    viewMode() {
      return this.viewOnly
    },

    canRun() {
      return [FormAction.CREATE, FormAction.EDIT].includes(this.formAction)
    },

    formAction() {
      if (this.viewOnly && this.id) return 'view'
      if (!this.viewOnly && this.id) return 'edit'
      return 'create'
    },
  },

  watch: {
    '$route.name': {
      handler(value) {
        this.resetLocalState()
        this.resetStore()
        this.$nextTick(async () => {
          if (this.id) {
            this.getQueryData()
          }
        })
      },

      immediate: true,
    },
  },

  methods: {
    ...mapMutations({
      clearAll: 'advancedSearch/CLEAR_ALL',
      updateFieldFilteringState: 'advancedSearch/UPDATE_FIELD_FILTERING_STATE',
      setFilterValue: 'advancedSearch/SET_FILTER_VALUE',
      setFilterCondition: 'advancedSearch/SET_FILTER_CONDITION',
      showDataSourceAdvancedForm: 'dashboard/SET_DATASOURCE_ADVANCED_FORM_VISIBILITY',
      setId: 'dashboard/SET_ID',
    }),

    async getQueryData() {
      const { response } = await this.$async(
        dashboardService('datasource-advanced').getOne(this.id),
      )

      this.setInitialData(response.data)
    },
    handleClearClick() {
      this.resetLocalState()
      this.resetStore()

      this.$root.$emit(RootEvents.ADV_SEARCH_CLEAR)
      this.showDataSourceAdvancedForm(false)
      this.setId(null)
    },
    isIncompleteInputs() {
      return (this.resource.name == null || this.resource.name === '')
            || (this.resource.query == null || this.resource.name.query === '')
            || this.runResult == null
    },
    async saveButtonClicked() {
      const errors = await this.validateInputs()
      if (errors.length) {
        return false
      }
      const payload = {
        id: this.resource.id,
        uuid: this.resource.uuidKey,
        name: this.resource.name,
        query: this.resource.query,
        companyFilterColumn: this.resource.companyFilterColumn,
        contactFilterColumn: this.resource.contactFilterColumn,
        key: this.resource.name.replace(' ', '_'),
        resultType: 'list',
      }
      const res = await dashboardService('datasource-advanced').saveAdvancedDatasource(payload)
      this.handleClearClick()
    },

    async handleRun() {
      this.runResult = null
      const errors = await this.validateInputs()
      if (errors.length) {
        return false
      }
      const payload = {
        query: this.resource.query,
      }
      const res = await advancedSearchService.runUnsavedQueryAdvanced(payload)
      const resultFirst = Object.keys(res.data.pageItems[0])

      this.runResult = res.data
    },

    resetLocalState() {
    },

    setInitialData(searchData) {
      this.resource = searchData
      // this.$root.$emit(RootEvents.SET_PAGE_TITLE, this.resource.name)
      if (searchData.companyFilterColumn != null) {
        this.resource.companyFilterColumn = searchData.companyFilterColumn
      }
      if (searchData.contactFilterColumn != null) {
        this.resource.contactFilterColumn = searchData.contactFilterColumn
      }
      const entities = this.setInitialSelectedEntities(
        this.resource.configuration,
      )

      this.$nextTick(() => {
        this.setInitialSelectedFields(entities)
      })
    },

    setInitialSelectedEntities(searchModel) {
      const entities = searchModel.entities.map(entity => {
        const id = `${entity.schemaName}.${entity.tableName}`

        return {
          ...this.entities.find(({ id: entityId }) => entityId === id),
          ...entity,
        }
      })

      entities.forEach(entity => this.$nextTick(() => this.handleTableClick(entity)))
      const hello = 'asd'
      return entities
    },

    async setInitialSelectedFields(entities) {
      entities.forEach(entity => {
        this.toggleCollapsedState(entity.id)
        entity.selectedFields.forEach(field => {
          this.addSelectedField({ entityId: entity.id, fieldKey: field.key })
        })
      })

      this.handleRun()
    },

    async validateInputs() {
      const errors = []
      return errors
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/mixins.scss';
@import '@/assets/scss/master-variables.scss';

.data-source-form-container {
  .column__content {
    background: var(--colour--card-bg);
  }
  .column__content {
    $approx-nav-footer-height: 210px;
    height: calc(90vh - #{$approx-nav-footer-height});
    overflow: auto;
  }

  .advanced-search__results {
    height: 100%;
  }

  .result-table-container {
    max-height: 89%;
    max-width: 100%;
    overflow: auto;
  }
}
</style>
